@import "~@stockifi/shared/src/styles/variables.scss";

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag-green {
  background-color: $green4 !important;
}

.tag-green-olive {
  background-color: #7fff00 !important;
}

.tag-orange {
  background-color: $orange4 !important;
}

.tag-dark-orange {
  background-color: #ff4c00 !important;
}

.tag-yellow {
  background-color: $gold5 !important;
}

.tag-light-purple {
  background-color: #9e55e2 !important;
}

.tag-light-green {
  background-color: #00ff00ee !important;
}

.tag-light-pink {
  background-color: #ff769c !important;
}

.tag-red {
  background-color: #b52538 !important;
}

.tag-alt-name {
  background-color: #ff78cb !important;
}

.tag-open-item {
  background-color: #ccc7af !important;
}

.tag-was-open-item {
  background-color: #ffd500 !important;
}

.tag-invoice {
  background-color: #de925b !important;
}

.tag-active {
  border-color: $white !important;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
}

.tag-sponsored {
  background-color: #ebe126 !important;
}

.tag-tax-deduction {
  background-color: #7fbdf0 !important;
}

.tag-discount {
  background-color: #aaff78 !important;
}

.tag-duplicate {
  background-color: #ff78cb !important;
}

.tag-total-computed {
  background-color: #0066ff !important;
  color: #ffffff !important;
}

.tag-light-green {
  background-color: lightgreen !important;
}

.tag-created-by-bot {
  background-color: #62b6ff !important;
}

.tag-duplicate-invoice {
  background-color: #7aff8c !important;
}

.tag-diverse-invoice {
  background-color: #7affe9 !important;
}

.tag-for-supervisor {
  background-color: #84b0fa !important;
}

.tag-draft {
  background-color: #ffe27a !important;
}

.tag-purple {
  background-color: #dea6ff !important;
}

.tag-uploaded-by-user {
  background-color: #d2e360 !important;
}

.tag-31-days {
  background-color: #91fcff !important;
}