.wrapper {
  max-width: 100%;
  width: 100%;
}

.child {
  display: none;
  -webkit-animation: fadein-content 436ms;
  -moz-animation: fadein-content 436ms;
  animation: fadein-content 436ms;
}
.child.show {
  display: block;
}

.load_indicator {
  background-color: rgba(245, 245, 245, 0.0823529412);
  width: inherit;
  height: 100%;
  min-height: 200px;
  position: sticky;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 11;
}
.load_indicator.avail {
  opacity: 0;
  display: none;
}

.frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 98%;
  z-index: 10;
  border-radius: 4px;
}

.head {
  background-color: #fac6fc;
  height: 3px;
  overflow: hidden;
  position: relative;
}

.load_indicator .first,
.load_indicator .second {
  background-color: #bb86fc;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.load_indicator .first {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}

.load_indicator .second {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein-content {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  25% {
    transform: translate(0) scaleX(0.5);
  }
  50% {
    transform: translate(25%) scaleX(0.75);
  }
  75% {
    transform: translate(100%) scaleX(0);
  }
  100% {
    transform: translate(100%) scaleX(0);
  }
}
@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  60% {
    transform: translate(0) scaleX(0);
  }
  80% {
    transform: translate(0) scaleX(0.6);
  }
  100% {
    transform: translate(100%) scaleX(0.1);
  }
}
.progress {
  background-color: #f5f5f5;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.progress .bar {
  background-color: #bb86fc;
  height: 100%;
  position: absolute;
  width: 0;
  z-index: 10;
  transition: width cubic-bezier(0.4, 0, 0.2, 1) 436ms;
}

